<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_accommodation.accommodation_management')}}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- <div class="col-6">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.guest_house_name')"
                          label-for="guest_house_id"
                      >
                        <v-select name="guest_house_id"
                            v-model="search.guest_house_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= guestHouseList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </div> -->
                    <div class="col-6">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="room_type_id"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.room_type')}}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.room_type_id"
                              :options="roomTypeList"
                              id="room_type_id"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                          </b-form-group>
                    </div>
                    <!-- <div class="col-6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="room_no"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.room_no')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.room_id"
                          :options="roomlist"
                          id="room_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </div> -->
                    <div class="col-6">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="start_date"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.start_date')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.start_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="end_date"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.end_date')}}
                          </template>
                          <b-form-input
                              class="fromDate"
                              v-model="search.end_date"
                              :placeholder="$t('globalTrans.select_date')"
                          ></b-form-input>
                        </b-form-group>
                    </div>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_accommodation.accommodation_management')}} {{$t('globalTrans.list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                      <!-- <b-button variant="primary" @click="pdfExport" class="mr-2">
                        {{  $t('globalTrans.export_pdf') }}
                      </b-button> -->
                      <router-link class="btn btn_add_new" :to="{ path: '/training-e-learning-service/accommodation-management/accommodation-form' }">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(name)="data">
                                        <span class="capitalize">{{ $i18n.locale === 'bn' ? data.item.guest_name_bn : data.item.guest_name }}</span>
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                            {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(start_date)="data">
                                            {{ data.item.check_in | dateFormat }}
                                        </template>
                                        <template v-slot:cell(end_date)="data">
                                            {{ data.item.check_out | dateFormat }}
                                        </template>
                                        <!-- <template v-slot:cell(date_of_birth)="data">
                                          <span class="capitalize">{{ data.item.date_of_birth | dateFormat }}</span>
                                        </template> -->
                                        <!-- <template v-slot:cell(accommodation_status)="data">
                                          <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                                          <span class="badge badge-success" v-if="data.item.status == 2">{{$t('globalTrans.approved')}}</span>
                                          <span class="badge badge-danger" v-if="data.item.status == 3">{{$t('globalTrans.rejected')}}</span>
                                        </template> -->
                                        <!-- <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                          <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template> -->
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm"> <router-link :to="{ path: '/training-e-learning-service/accommodation-management/accommodation-form', query: { id: data.item.id }}" size="sm"><i class="ri-ball-pen-fill m-0"></i> </router-link></b-button>
                                            <b-button class="mr-1" title="View Details" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                            <span v-if="data.item.status === 1">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fas fa-window-close m-0"></i></b-button>
                                            </span>
                                            <span v-if="data.item.status === 2">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fa fa-check m-0"></i></b-button>
                                            </span>
                                           <b-button v-if="data.item.bill_generate_status === 1" v-b-modal.modal-6 variant="btn btn-xs btn-info button-customize-for-font" size="sm" @click="edit(data.item)"><i class="fab fa-paypal"></i> <i class="fas fa-money-bill"></i> {{$t('elearning_venue.bill_generate')}}</b-button>
                                           -->
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                          <!-- <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a> -->
                                          <!-- <a href="javascript:" class="btn_table_action table_action_edit" title="Edit"> <router-link :to="{ path: '/training-e-learning-service/accommodation-management/accommodation-form', query: { id: data.item.id }}" size="sm"><i class="ri-ball-pen-fill"></i> </router-link></a> -->
                                          <!-- <a href="javascript:" class="btn_table_action table_action_status" title="Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a> -->
                                          <!-- <a href="javascript:" v-if="data.item.status == 1" :title="$t('globalTrans.approve')" v-b-modal.modal-approve-reject class="btn_table_action table_action_status" size="sm" @click="approveReject(data.item.id, 2)"><i class="fa fa-check"></i></a>
                                          <a href="javascript:" v-if="data.item.status == 1" :title="$t('globalTrans.reject')" v-b-modal.modal-approve-reject class="btn_table_action table_action_toggle" size="sm" @click="approveReject(data.item.id, 3)"><i class="fas fa-times"></i></a> -->
                                          <!-- <a href="javascript:" v-if="data.item.bill_generate_status === 1" v-b-modal.modal-6 variant="btn btn_table_action table_action_msg button-customize-for-font" @click="edit(data.item)"><i class="fas fa-money-bill"></i></a> -->
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_accommodation.accommodation_management') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <!-- <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button> -->
            <DetailsBina :item="item" :key="id" ref="details"/>
        </b-modal>
        <b-modal id="modal-6" size="xl" :title="$t('elearning_venue.bill_generate')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <BillGenerate :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-approve-reject" size="md" :title="status === 2 ? $t('globalTrans.approve') : $t('globalTrans.reject')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <approveRejectBina :id="itemId" :accommodationStatus="accommodation_status"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
// import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { accommodationManagementListBina, accommodationManagementToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Store from '@/store'
import flatpickr from 'flatpickr'
import DetailsBina from './DetailsBina.vue'
import BillGenerate from './BillGenerate'
import approveRejectBina from './approveRejectBina.vue'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        DetailsBina,
        BillGenerate,
        approveRejectBina
    },
    data () {
        return {
            search: {
              org_id: 0,
              room_type_id: 0,
              // room_id: 0,
              // guest_house_id: 0,
              start_date: '',
              end_date: ''
            },
            id: 0,
            roomlist: [],
            guestHouseList: [],
            // roomTypeList: [],
            item: '',
            itemId: '',
            accommodation_status: '',
            status: '',
            datas: []
        }
    },
    computed: {
      roomTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
          if (this.$store.state.Auth.authUser.org_id === 12) {
              return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
          } else {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          }
      },
    //   guestHouseList: function () {
    //     return this.$store.state.TrainingElearning.commonObj.guestHouseList
    //   },
        trainerEvaluationList: function () {
          return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_accommodation.accommodation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_accommodation.accommodation') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('org_pro.organization'), class: 'text-center' },
                { label: this.$t('globalTrans.name'), class: 'text-center' },
                { label: this.$t('globalTrans.mobile'), class: 'text-center' },
                { label: this.$t('elearning_accommodation.check_in'), class: 'text-center' },
                { label: this.$t('elearning_accommodation.check_out'), class: 'text-center' },
                // { label: this.$t('globalTrans.status'), class: 'text-center' },
                // { label: this.$t('elearning_config.guest_house_name'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'org_bn' },
                { key: 'name' },
                { key: 'mobile' },
                { key: 'start_date' },
                { key: 'end_date' },
                // { key: 'accommodation_status' },
                // { key: 'guest_house_bn' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'org' },
                { key: 'name' },
                { key: 'mobile' },
                { key: 'start_date' },
                { key: 'end_date' },
                // { key: 'guest_house' },
                // { key: 'accommodation_status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
      // 'search.guest_house_id': function (newValue) {
      //   this.roomTypeList = this.getRoomTypeList(newValue)
      // },
      // 'search.room_type_id': function (newValue) {
      //   this.roomlist = this.getRoomList(newValue)
      // },
      // 'search.org_id': function (newValue) {
      //   this.guestHouseList = this.getGuestHouseList(newValue)
      // }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      this.loadData()
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    methods: {
        approveReject (id, accommodationStatus) {
          this.itemId = id
          this.accommodation_status = accommodationStatus
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, accommodationManagementToggleStatus, item)
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, accommodationManagementListBina, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const guestHouseObj = this.$store.state.TrainingElearning.commonObj.guestHouseList.find(doc => doc.value === parseInt(item.guest_house_id))
            const guestHouseData = {}
            if (typeof guestHouseObj !== 'undefined') {
              guestHouseData.guest_house = guestHouseObj.text_en
              guestHouseData.guest_house_bn = guestHouseObj.text_bn
            } else {
              guestHouseData.guest_house = ''
              guestHouseData.guest_house_bn = ''
            }

             const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officeTypeData = {}
            if (typeof officeTypeObj !== 'undefined') {
              officeTypeData.office_type = officeTypeObj.text_en
              officeTypeData.office_type_bn = officeTypeObj.text_bn
            } else {
              officeTypeData.office_type = ''
              officeTypeData.office_type_bn = ''
            }

            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }

            const OfficeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const OfficeData = {}
            if (typeof OfficeObj !== 'undefined') {
              OfficeData.office_name = OfficeObj.text_en
              OfficeData.office_name_bn = OfficeObj.text_bn
            } else {
              OfficeData.office_name = ''
              OfficeData.office_name_bn = ''
            }
            const guestOrgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.guest_org_id))
            const guestOrgData = {}
            if (typeof guestOrgObj !== 'undefined') {
              guestOrgData.guest_org = guestOrgObj.text_en
              guestOrgData.guest_org_bn = guestOrgObj.text_bn
            } else {
              guestOrgData.guest_org = ''
              guestOrgData.guest_org_bn = ''
            }
            // var temp = []
            // temp = item.food_package_id.split(',')
            // const checkData = []
            // const checkDataBn = []
            // temp.map((item1, key) => {
            //   const foodPackageObj = this.$store.state.TrainingElearning.commonObj.foodPackageList.find(doc => doc.value === parseInt(item1))
            //   if (typeof foodPackageObj !== 'undefined') {
            //     checkData[key] = foodPackageObj.text_en
            //     checkDataBn[key] = foodPackageObj.text_bn
            //   }
            // })
            // const foodPackageData = {}
            // foodPackageData.food_pacakge = checkData
            // foodPackageData.food_pacakge_bn = checkDataBn
            return Object.assign({}, item, orgData, guestHouseData, OfficeData, fiscalYearData, officeTypeData, guestOrgData)
          })
          return listData
        },
        // getRoomTypeList (houseId = null) {
        //     const houseList = this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
        //     if (houseId) {
        //         return houseList.filter(house => house.guest_house_id === houseId)
        //     }
        //     return houseList
        // },
        getGuestHouseList (orgId = null) {
            const houseList = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
            if (orgId) {
                return houseList.filter(org => org.org_id === orgId)
            }
            return houseList
        },
        getRoomList (typeId) {
        const roomlist = this.$store.state.TrainingElearning.commonObj.roomList.filter(item => item.status === 1 && item.room_type_id === typeId)
        const tempData = roomlist.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
      pdfExport () {
          this.$refs.details.pdfExport()
      }
    }
}
</script>
