import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
    //   const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      pdfContent.push({ text: '', style: 'fertilizer' })
      pdfContent.push({ text: vm.$t('portal.sd_1'), style: 'sp1' })
      pdfContent.push({ text: vm.$t('portal.sd_2'), style: 'sp2' })
      pdfContent.push({ text: vm.$t('portal.sd_3'), style: 'sp2' })
      pdfContent.push({ text: vm.$t('portal.sd_4'), style: 'sp2' })
      pdfContent.push({ text: vm.$t('portal.acc_rqst'), style: 'sp3' })
      pdfContent.push({
          table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['23%', '2%', '75%'],
              body: [
                  [
                      { text: vm.$t('portal.applicant_information'), style: 'th1', alignment: 'left', colSpan: 3 },
                      {},
                      {}
                  ],
                  [
                      { text: vm.$t('portal.applicant_type'), style: 'ths', alignment: 'left' },
                      { text: ':', style: 'ths', alignment: 'center' },
                      { text: vm.getProfessionType(data.p_profession_type), style: 'tds', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('portal.applicant_name'), style: 'th', alignment: 'left' },
                      { text: ':', style: 'th', alignment: 'center' },
                      { text: (i18n.locale === 'en') ? data.applicant_name : data.applicant_name_bn, style: 'td', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'left' },
                      { text: ':', style: 'th', alignment: 'center' },
                      { text: vm.$n('0') + vm.$n(data.mobile, { useGrouping: false }), style: 'td', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('globalTrans.email'), style: 'th', alignment: 'left' },
                      { text: ':', style: 'th', alignment: 'center' },
                      { text: data.email, style: 'td', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('portal.name_institution'), style: 'th', alignment: 'left' },
                      { text: ':', style: 'th', alignment: 'center' },
                      { text: (i18n.locale === 'en') ? data.institution_name : data.institution_name_bn, style: 'td', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('portal.applicant_designation'), style: 'th', alignment: 'left' },
                      { text: ':', style: 'th', alignment: 'center' },
                      { text: (i18n.locale === 'en') ? data.applicant_designation : data.applicant_designation_bn, style: 'td', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('portal.address_individuals_institution'), style: 'th', alignment: 'left' },
                      { text: ':', style: 'th', alignment: 'center' },
                      { text: (i18n.locale === 'en') ? data.address : data.address_bn, style: 'td', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('portal.room_use_info'), style: 'th1', alignment: 'left', colSpan: 3 },
                      {},
                      {}
                  ],
                  [
                      { text: vm.$t('portal.use_purpose'), style: 'ths', alignment: 'left' },
                      { text: ':', style: 'ths', alignment: 'center' },
                      { text: vm.getUsePurpose(data.use_purpose), style: 'tds', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('globalTrans.title'), style: 'ths', alignment: 'left' },
                      { text: ':', style: 'ths', alignment: 'center' },
                      { text: (i18n.locale === 'en') ? data.training_title : data.training_title_bn, style: 'tds', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('elearning_accommodation.check_in'), style: 'th', alignment: 'left' },
                      { text: ':', style: 'th', alignment: 'center' },
                      { text: dateFormat(data.start_date), style: 'td', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('elearning_accommodation.check_out'), style: 'th', alignment: 'left' },
                      { text: ':', style: 'th', alignment: 'center' },
                      { text: dateFormat(data.end_date), style: 'td', alignment: 'left' }
                  ],
                  [
                      { text: vm.$t('portal.user_information'), style: 'th1', alignment: 'left', colSpan: 3 },
                      {},
                      {}
                  ]
              ]
          },
          layout: {
              hLineWidth: function (i, node) {
                  return 0
              },
              vLineWidth: function (i, node) {
                  return 0
              }
          }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
      if (parseInt(data.p_profession_type) === 1) {
          const committeeRows = [
              [
                  { text: vm.$t('portal.grade_from'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.grade_to'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.male_trainee'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.female_trainee'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.total_trainee'), style: 'thh', alignment: 'right' }
              ]
          ]
          data.user_details.forEach((info, index) => {
              committeeRows.push([
                  { text: vm.getGradeForm(info.grade_from), style: 'td', alignment: 'right' },
                  { text: vm.getGradeTo(info.grade_to), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.male_trainee), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.female_trainee), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.total_trainee), style: 'td', alignment: 'right' }
              ])
          })
          pdfContent.push({
              table: {
                  headerRows: 1,
                  widths: ['20%', '20%', '20%', '20%', '20%'],
                  body: committeeRows
              }
          })
      }
      if (parseInt(data.p_profession_type) === 2 || parseInt(data.p_profession_type) === 3) {
          const committeeRows = [
              [
                  { text: vm.$t('portal.officer_type'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.male_trainee'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.female_trainee'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.total_trainee'), style: 'thh', alignment: 'right' }
              ]
          ]
          data.user_details.forEach((info, index) => {
              committeeRows.push([
                  { text: vm.getOfficerType(info.officer_type), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.male_trainee), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.female_trainee), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.total_trainee), style: 'td', alignment: 'right' }
              ])
          })
          pdfContent.push({
              table: {
                  headerRows: 1,
                  widths: ['25%', '25%', '25%', '25%'],
                  body: committeeRows
              }
          })
      }
      pdfContent.push({ text: '', style: 'fertilizer' })
      pdfContent.push({
          table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['23%', '2%', '75%'],
              body: [
                  [
                      { text: vm.$t('portal.room_information'), style: 'th1', alignment: 'left', colSpan: 3 },
                      {},
                      {}
                  ]
              ]
          },
          layout: {
              hLineWidth: function (i, node) {
                  return 0
              },
              vLineWidth: function (i, node) {
                  return 0
              }
          }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
          const committeeRows = [
              [
                  { text: vm.$t('portal.grade_from'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.grade_to'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('portal.room_type'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('elearning_config.room_number'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('elearning_config.room_rent'), style: 'thh', alignment: 'right' },
                  { text: vm.$t('elearning_config.total_rent'), style: 'thh', alignment: 'right' }
              ]
          ]
          data.room_details.forEach((info, index) => {
              committeeRows.push([
                  { text: vm.getGradeForm(info.grade_from), style: 'td', alignment: 'right' },
                  { text: vm.getGradeTo(info.grade_to), style: 'td', alignment: 'right' },
                  { text: vm.getRoomType(info.room_type_id), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.room_number), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.room_rent), style: 'td', alignment: 'right' },
                  { text: vm.$n(info.total_rent), style: 'td', alignment: 'right' }
              ])
          })
          pdfContent.push({
              table: {
                  headerRows: 1,
                  widths: ['15%', '15%', '15%', '15%', '20%', '20%'],
                  body: committeeRows
              }
          })
      pdfContent.push({ text: '', style: 'fertilizer' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      pdfContent.push({
          table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['30%', '30%', '40%'],
              body: [
                  [
                      { text: '', style: 'ths', alignment: 'left' },
                      { text: '', style: 'ths', alignment: 'center' },
                      { text: vm.$t('portal.sd_6') + '\n' + ((i18n.locale === 'en') ? data.applicant_name : data.applicant_name_bn) + '\n' + (i18n.locale === 'bn' ? data.applicant_designation_bn : data.applicant_designation) + '\n' + (i18n.locale === 'bn' ? data.address_bn : data.address), style: 'th', alignment: 'center' }
                  ]
              ]
          },
          layout: {
              hLineWidth: function (i, node) {
                  return 0
              },
              vLineWidth: function (i, node) {
                  return 0
              }
          }
      })
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            ths: {
                fillColor: '',
                fontSize: (i18n === 'bn') ? 12 : 10,
                // bold: true,
                margin: [3, 10, 3, 2]
            },
            th: {
                fillColor: '',
                fontSize: (i18n === 'bn') ? 12 : 10,
                // bold: true,
                margin: [3, 2, 3, 2]
            },
            thh: {
                fillColor: '',
                fontSize: (i18n === 'bn') ? 12 : 10,
                bold: true,
                margin: [3, 2, 3, 2]
            },
            th1: {
                fillColor: '#02813e',
                color: 'white',
                fontSize: (i18n === 'bn') ? 14 : 12,
                // bold: true,
                margin: [3, 3, 3, 3]
            },
            tds: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [3, 10, 3, 3]
            },
            td: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [3, 2, 3, 2]
            },
            sp1: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [3, 3, 3, 8]
            },
            sp2: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [3, 2, 3, 2]
            },
            sp3: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [3, 8, 3, 8]
            },
            header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 14,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
            header2: {
                fontSize: 15,
                bold: true,
                margin: [15, 15, 25, 10]
            },
            header11: {
                fontSize: 13,
                bold: true,
                margin: [15, 10, 10, 10]
            },
            transport: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [10, 10, 0, 20]
            },
            headerPort1: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [0, 20, 0, 0]
            },
            headerPort: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [0, 4, 0, 15]
            },
            header3: {
                fontSize: 9,
                margin: [0, 0, 0, 4]
            },
            tableSubHead: {
                margin: [0, 5, 0, 15]
            },
            krishi: {
                margin: [0, -15, 0, 15],
                alignment: 'center'
            }
        }
    }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
