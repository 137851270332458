import { render, staticRenderFns } from "./BillGenerate.vue?vue&type=template&id=3f96b928&scoped=true&"
import script from "./BillGenerate.vue?vue&type=script&lang=js&"
export * from "./BillGenerate.vue?vue&type=script&lang=js&"
import style0 from "./BillGenerate.vue?vue&type=style&index=0&id=3f96b928&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f96b928",
  null
  
)

export default component.exports