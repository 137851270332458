<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col cols="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                            <ValidationProvider name="Approve/Reject Comment" vid="ar_comment" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="ar_comment"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('elearning_iabm.comment') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                id="ar_comment"
                                v-model="formData.ar_comment"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-approve-reject')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { AccommodationServiceRequestApproveRejectBina } from '../../api/routes'

export default {
  props: ['id', 'accommodationStatus'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      this.formData.id = this.id
      this.formData.accommodation_status = this.accommodationStatus
      this.formData.approve_reject_by = this.$store.state.Auth.authUser.user_id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.accommodationStatus === 2 ? this.$t('globalTrans.approve') : this.$t('globalTrans.reject'),
      formData: {
        ar_comment: ''
      }
    }
  },
  computed: {
  },
  methods: {
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      result = await RestApi.postData(trainingElearningServiceBaseUrl, AccommodationServiceRequestApproveRejectBina, this.formData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: result.message,
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-approve-reject')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
